import { graphql, useStaticQuery } from 'gatsby';
import { SwiperSlide } from 'swiper/react/swiper-react';
import { SectionText } from '../../../utils/TextLabel';
import ResponsiveSwiper from '../../Common/ResponsiveSwiper';

interface TAwardNode {
  node: {
    id: string;
    content: string;
    presenter: string;
    year: string;
    logo: {
      localFile: {
        publicURL: string;
      };
    };
  };
}

interface TAwards {
  allStrapiOurAwards: {
    edges: TAwardNode[];
  };
}

const AwardsSection: React.FC = () => {
  const data: TAwards = useStaticQuery(graphql`
  query AwardsSectionQuery {
    allStrapiOurAwards {
      edges {
        node {
          id
          content
          presenter
          year
          logo {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
  `);

  return (
    <div
      className="w-full xl:w-[1200px] flex flex-col items-center pt-10 pb-20 mt-10 mx-auto"
      style={{
        backgroundImage: 'radial-gradient(#DADADA 15%, transparent 10%), radial-gradient(#DADADA 15%, transparent 10%)',
        backgroundColor: '#FFF',
        backgroundPosition: '0px 30px, 40px 30px',
        backgroundSize: '40px 40px',
      }}
    >
      <SectionText className="mt-[1.95rem]">Our Awards</SectionText>
      <div className="w-full bg-white flex flex-col items-center justify-center pt-8 pb-4 md:py-8 mt-20">
        <ResponsiveSwiper
          className="md:px-pageContainerSpace lg:px-pageContainerSpace"
        >
          {data && data.allStrapiOurAwards.edges
            .sort((a, b) => Number(a.node.year) - Number(b.node.year))
            .map((item) => (
              <SwiperSlide
                key={item.node.content}
                className="mb-8 mt-4"
              >
                <div className="w-full flex flex-col gap-y-3 items-center text-spaceGray">
                  <span className="font-semibold text-xl">{item.node.year}</span>
                  <div className="w-9 h-[3px] bg-orange" />
                  <div className="w-[150px] h-[160px] mt-2 flex flex-col items-center justify-center">
                    <img
                      alt={`Award ${item.node.year}`}
                      src={item.node.logo.localFile.publicURL}
                      // className="h-7 md:h-10 w-auto mb-2 object-cover"
                      className="mb-2 w-full h-full object-contain"
                    />
                  </div>
                  <span className="text-base text-center font-light w-[180px]">{item.node.content}</span>
                </div>
              </SwiperSlide>
            ))
          }
        </ResponsiveSwiper>
      </div>
    </div>
  );
};

export default AwardsSection;