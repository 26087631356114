import React from 'react';
import { useNavigate } from '@reach/router';
import tw, { styled } from 'twin.macro';
import { ServiceList, TServiceDetail } from '../../../../utils/contents/services-detail';
import { RouteName } from '../../../../utils/enums';
import { SectionText } from '../../../../utils/TextLabel';

const Column = styled.div(() => [tw`max-w-full gap-9 bg-transparent grid-cols-1 grid md:grid-cols-3`]);

interface TLetUsHelpSection {
  className?: string;
  isHoveredService: TServiceDetail | null;
  setIsHoveredService: (serviceDetail: TServiceDetail | null) => void;
}

const LetUsHelpSection: React.FC<TLetUsHelpSection> = ({ className, isHoveredService, setIsHoveredService }) => {
  const navigate = useNavigate();

  return (
    <div className={`w-full flex flex-col ${className}`}>
      <SectionText className="mx-auto">Let us help you</SectionText>
      <Column className="mt-16">
        {ServiceList.map((serviceDetail, index) => {
          const isEvenNumber = (index + 1) % 2 !== 0;
          return (
            <div key={serviceDetail.title} className="w-full relative flex flex-col justify-center items-center">
              <div className={`w-[16rem] h-[16rem] md:w-[18rem] md:h-[18rem] rounded-full flex flex-col relative items-center justify-center`}>
                <img
                  alt={serviceDetail.title}
                  src={serviceDetail.bgImage}
                  className="w-full h-full rounded-full absolute z-10 object-cover"
                />
                <div className={`absolute pointer-events-none ${isHoveredService?.title === serviceDetail.title ? 'z-40' : 'z-20'} flex flex-col justify-center items-center p-6 cursor-pointer`}>
                  <span className={`font-medium text-2xl md:text-[26px] text-center mx-auto leading-10 ${isHoveredService?.title === serviceDetail.title ? 'text-white' : isEvenNumber ? 'text-white' : 'text-spaceGray'}`}>{serviceDetail.title}</span>
                  <div className={`w-7 h-[3px] mt-1 ${isHoveredService?.title === serviceDetail.title ? 'bg-white' : isEvenNumber ? 'bg-white' : 'bg-orange'}`} />
                </div>
                <div
                  className="w-full h-full rounded-full absolute z-30 cursor-pointer hover:bg-black hover:bg-opacity-50 transform transition"
                  onMouseOver={() => setIsHoveredService(serviceDetail)}
                  onMouseLeave={() => setIsHoveredService(null)}
                  onClick={() => navigate(RouteName.CONTACT_US)}
                />
              </div>
            </div>
          );
          // return (
          //   <Box
          //     key={serviceDetail.title}
          //     url={serviceDetail.bgImage}
          //     onMouseEnter={() => handleHover(index)}
          //     onMouseLeave={() => handleUnHover(index)}
          //   >
          //     <Overlay isHovered={isHovered[index.toString()]} />
          //     <div className="z-10 flex-grow">
          //       <BoxTitle fontColor={isHovered[index.toString()] ? 'white' : serviceDetail.fontColor}>
          //         {serviceDetail.title}
          //       </BoxTitle>
          //       <Desc isHovered={isHovered[index.toString()]}>{serviceDetail.desc}</Desc>
          //     </div>
          //     <Button
          //       type={!isEvenNumber ? ButtonType.WHITE_GHOST : ButtonType.BLACK_GHOST}
          //       onClick={() => navigate('/contact')}
          //       className="mx-auto"
          //     >
          //       Tell us more
          //     </Button>
          //   </Box>
          // );
        })}
      </Column>
    </div>
  );
};

export default LetUsHelpSection;
