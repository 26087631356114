import { PageHeaderText } from '../../../utils/TextLabel';
import WhoWeAre from '../../../assets/pages/WhoWeAre.png';

const IntroductionSection: React.FC = () => {
  return (
    <div className="w-full xl:w-pageContainerSize px-pageContainerSpace xl:px-0 flex flex-col mx-auto">
      <div className="w-full flex flex-col lg:flex-row">
        <div className="w-full md:w-[90%] lg:w-[80%] flex flex-col gap-y-3 lg:gap-y-5">
          <PageHeaderText>Who we are?</PageHeaderText>
          <p className="leading-7 text-sm md:text-lg font-light w-[90%]">
            Orange Cap Innovative is an IT Consultant & Venture Builder which helps corporates to develop the new technology-based business. We serves our clients from business model consultation, technology development to product launch with our team&rsquo;s expertise that got achievements in the international scale.
          </p>
          <p className="leading-7 text-sm md:text-lg font-light w-[90%]">
            Orange Cap Innovative team&rsquo;s DNA is from startup cultures. We have built and own a few successful products. This makes us understand the dynamics of the technology-business. Plus, our management team has full experiences in digital product development.
          </p>
        </div>
        <div className="w-full flex flex-col items-center lg:items-end pt-4 lg:pt-20">
          <img
            alt="Introduction"
            src={WhoWeAre}
            className="w-[90%]"
          />
        </div>
      </div>
    </div>
  );
};

export default IntroductionSection;