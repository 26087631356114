import Ideas from '../../assets/Ideas.svg';
import Scale from '../../assets/Scale.svg';
import Revenue from '../../assets/Revenue.svg';

export interface TServiceDetail {
  title: string;
  buttonTitle: string;
  desc: string;
  buttonUrl: string;
  borderColor: string;
  fontColor: string;
  bgImage: string;
  detail: string[];
}

export const ServiceList: TServiceDetail[] = [
  {
    title: 'Turn your ideas into reality',
    buttonTitle: 'Find out more',
    buttonUrl: '',
    borderColor: 'white',
    fontColor: 'white',
    bgImage: Ideas,
    desc: 'We provide the service that will match you idea from the very beginning and make it happen for you.',
    detail: [
      'Build new Innovative Business',
      'Business Model & Business Strategy Analysis',
      'Proposal & Project Feasibility',
      'User Research',
      'Product (Technology) Development',
      'Operation Setup & Management',
      'Product Launching',
      'Maintenance'
    ]
  },
  {
    title: 'Scale up your business',
    buttonTitle: 'Find out more',
    buttonUrl: '',
    borderColor: '#283941',
    fontColor: '#283941',
    desc: 'If you are a company that aim for scaling up by using Technology. We think you come into the right choice.',
    bgImage: Scale,
    detail: [
      'Solution Consultant',
      'User Experience (UX) Study & Design',
      'Process Optimization',
      'Product (Technology)',
      'Development',
      'Mobile Application (iOS & Android)',
      'Web Application, Software',
      'User Interface Design',
      'Optimise Tech Infrastructure',
      'Documentation',
      'UAT/SIT',
      'System Maintenance'
    ]
  },
  {
    title: 'Grow your revenue',
    buttonTitle: 'Find out more',
    buttonUrl: '',
    borderColor: 'white',
    fontColor: 'white',
    desc:
      'We can support your growth direction with our expertize and customize the solution to make your business outstanding from other competitors.',
    bgImage: Revenue,
    detail: [
      'Online Advertising',
      'Press Release',
      'Content Marketing',
      'Influencer Marketing',
      'Social Media Advertising',
      'Digital Film and Animation'
    ]
  }
];