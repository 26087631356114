import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { OurValueContents } from '../../../utils/contents/our-values';
import { SectionText } from '../../../utils/TextLabel';

const ValuesSection: React.FC = () => {
  return (
    <div className="w-full flex flex-col items-center pt-6">
      <SectionText>Our Values</SectionText>
      <div className="relative w-full overflow-x-auto flex px-pageContainerSpace mt-6">
        <Swiper
          modules={[Pagination]}
          spaceBetween={30}
          pagination={{ clickable: true }}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            600: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 4,
              pagination: false,
            },
          }}
        >
          {OurValueContents.map((content) => (
            <SwiperSlide
              key={content.id}
              className="mt-4 mb-8"
            >
              <div className="flex flex-col items-center">
                <img
                  alt={content.head_text}
                  src={content.image_url}
                  className="w-[4.75rem] h-[4.75rem] object-cover"
                />
                <span className="text-spaceGray font-semibold text-xl mt-7">{content.head_text}</span>
                <p className="text-spaceGray font-light text-base mt-1">{content.sub_text}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ValuesSection;
