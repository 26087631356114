import React, { useState } from 'react';
import Page from '../components/Common/Page';
import IntroductionSection from '../components/pages/WhoWeArePage/IntroductionSection';
import AwardsSection from '../components/pages/WhoWeArePage/AwardsSection';
import ValuesSection from '../components/pages/WhoWeArePage/ValuesSection';
import IndexLayout from '../layouts';
import LetUsHelpSection from '../components/pages/IndexPage/HowCanWeHelpSection/LetUsHelpSection';
import { TServiceDetail } from '../utils/contents/services-detail';
import { Button, ButtonType } from '../components/Common/Button';
import { useNavigate } from '@reach/router';
import { RouteName } from '../utils/enums';

const WhoWeAre: React.FC = () => {
  const navigate = useNavigate();
  const [isHoveredService, setIsHoveredService] = useState<TServiceDetail | null>(null);

  return (
    <IndexLayout transparent>
      <Page className="w-full flex flex-col gap-y-4 xl:gap-y-14">
        <IntroductionSection />
        <AwardsSection />
        <ValuesSection />
        <LetUsHelpSection
          className="xl:-mt-8"
          isHoveredService={isHoveredService}
          setIsHoveredService={setIsHoveredService}
        />
        <Button
          type={ButtonType.SECONDARY}
          onClick={() => navigate(RouteName.WORK)}
          className="mx-auto mt-6 mb-16"
        >
          What we build
        </Button>
      </Page>
    </IndexLayout>
  );
};

export default WhoWeAre;