import Deliver from '../../assets/values-deliver.svg';
import Think from '../../assets/values-think.svg';
import Create from '../../assets/values-create.svg';
import Customize from '../../assets/values-customize.svg';

export interface TOurValues {
  id: string;
  image_url: string;
  head_text: string;
  sub_text: string;
}

export const OurValueContents: TOurValues[] = [
  {
    id: '1',
    image_url: Deliver,
    head_text: 'Deliver',
    sub_text: 'WOW Services'
  },
  {
    id: '2',
    image_url: Think,
    head_text: 'Think',
    sub_text: 'Beyond Boundaries'
  },
  {
    id: '3',
    image_url: Create,
    head_text: 'Create',
    sub_text: 'Bussiness Impact'
  },
  {
    id: '4',
    image_url: Customize,
    head_text: 'Customize',
    sub_text: 'Your Success Cap'
  },
];