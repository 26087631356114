import React from 'react';
import { Swiper } from 'swiper/react/swiper-react';
import { Pagination, Navigation } from 'swiper';

interface TResponsiveSwiper {
  className?: string;
}

const ResponsiveSwiper: React.FC<TResponsiveSwiper> = ({ children, className }) => {
  return (
    <div className={`relative w-full overflow-x-auto flex md:px-56 lg:px-24 xl:px-0 ${className}`}>
      <Swiper
        modules={[Navigation, Pagination]}
        pagination={{ clickable: true }}
        navigation
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
          1280: {
            slidesPerView: 4,
          }
        }}
      >
        {children}
      </Swiper>
    </div>
  );
};

export default ResponsiveSwiper;
